.App {
  font-family: "DM Sans", sans-serif;
  font-weight: bold;
  text-align: center;
}

.Home {
  height: 100vh;
  background-color: #ffbe4f;
  background: url(./images/screen01Bg.svg);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}

.mobile {
  display: none;
  visibility: hidden;
}

@media screen and (max-width: 768px) {
  .Home {
    display: none;
    visibility: none;
  }
  .mobile {
    display: block;
    visibility: visible;
    font-size: 2rem;
    display: grid;
    place-items: center;
    height: 100vh;
    padding: 4rem;
  }
}

.Patients {
  color: white;
  background-color: #ffbe4f;
  background: url(./images/screen01Bg.svg);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}

.Messages {
  height: 100vh;
  background-color: #1629f0;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}

.Tasks {
  color: white;
  background-color: #ffbe4f;
  background: url(./images/screen01Bg.svg);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}

.Video {
  height: 100vh;
  background-color: #1629f0;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}

.End {
  height: 100vh;
  background-color: #ffbe4f;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}

.button {
  background-color: black;
  border: 0;
  border-radius: 120px;
  font: inherit;
  line-height: 12vh;
  padding: 2vh 10vh;
  text-decoration: none;
  font-size: 4vh;
  color: white;
  outline: none;
  transition: all 0.3s;
}

.button:hover,
.button:focus {
  box-shadow: inset 0 0 0 2em #d60a52;
}

.load-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 10;
  pointer-events: none;
}

.load-screen {
  position: relative;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #00b581;
  width: 0%;
  height: 100%;
}

.load-screen1 {
  position: relative;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #1629f0;
  width: 100%;
  height: 0%;
}

.load-screen3 {
  position: relative;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #ffbe4f;
  width: 100%;
  height: 0%;
}

.end-screen {
  position: relative;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #ffbe4f;
  width: 0%;
  height: 100%;
}

.screen1-content-wrapper {
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
}

.screen1-title {
  color: #2b2b2b;
  font-size: 8vh;
  width: 80%;
  margin: 0 auto 0 auto;
}

.screen1-pretitle {
  color: #d60a52;
  font-size: 3vh;
  margin-bottom: 4rem;
}

.screen1-subtitle {
  color: #2b2b2b;
  font-size: 3vh;
  margin-bottom: 4rem;
  margin-top: 3vh;
}

.screen1-twinkle {
  position: absolute;
  z-index: 5;
}

.screen1-twinkle-small {
  position: absolute;
  z-index: 5;
  -webkit-transform: scaleY(0.5) scaleX(0.5);
  transform: scaleY(0.5) scaleX(0.5);
}

.svg-twinkle {
  width: 5vw;
}

.screen1-heart {
  position: absolute;
  z-index: 5;
}

.svg-heart {
  width: 8vw;
}

.load-screen2 {
  position: relative;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 10;
}

.screen2-background {
  background-color: #1629f0;
  width: 100%;
  height: 100vh;
}

.screen2-layout {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
  height: 100vh;
  z-index: 2;
  position: absolute;
}

.screen2-bg-overlay {
  background-color: #1421d5;
  width: calc(100vh * 1.3);
  height: calc(100vh * 1.3);
  position: absolute;
  z-index: 1;
  border-radius: calc(100vh * 1.3);
  left: calc(100vh * -0.3);
  top: calc(100vh * -0.2);
}

.screen2-left {
  display: grid;
  place-items: center;
}

.screen2-right {
  display: grid;
  place-items: center;
}

.screen2-type-big {
  width: 100%;
  height: 100%;
  font-size: 15vw;
  line-height: 12vw;
  margin: 0;
  padding: 0;
  color: #ffbe4f;
  transform: translate(200px, 0);
}

.outline {
  -webkit-text-fill-color: rgba(
    0,
    0,
    0,
    0
  ); /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 0.2vh;
  -webkit-text-stroke-color: #ffbe4f;
}

.screen2-left {
  font-size: 1.6rem;
  text-align: left;
  padding-left: 40px;
}

.screen2-title {
  font-size: 5rem;
  color: #ffbe4f;
  margin: 20px 0 20px 0;
}

.screen2-subtitle {
  margin-right: 20%;
  margin-bottom: 40px;
}

.screen3-background {
  height: 100vh;
  background-color: #ffbe4f;
  background: url(./images/screen01Bg.svg);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}

.screen3-left-background {
  background-color: #facfd0;
  width: 60vh;
  height: 60vh;
  border-radius: 60vh;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transform: translate(-50%, 100%);
  top: 50%;
  left: 25%;
}

.screen3-message-icon01 {
  position: absolute;
  z-index: 2;
  left: 8%;
  top: 5%;
}

.screen3-message-icon02 {
  position: absolute;
  z-index: 2;
  right: 60%;
  top: 20%;
  -webkit-transform: scaleY(0.7) scaleX(0.7) rotate(40deg);
  transform: scaleY(0.7) scaleX(0.7) rotate(40deg);
}

.screen3-message-icon03 {
  position: absolute;
  z-index: 2;
  left: 16%;
  bottom: 12%;
  -webkit-transform: scaleY(0.5) scaleX(0.5) rotate(10deg);
  transform: scaleY(0.5) scaleX(0.5) rotate(10deg);
}

.message-svg {
  width: 15vh;
}

.screen3-left-content {
  z-index: 5;
  color: #d60a52;
  font-size: 3.5vh;
  position: absolute;
  opacity: 0;
  transform: translate(-50%, 100%);
  top: 50%;
  left: 25%;
}

.screen3-title {
  font-size: 10vh;
  color: #454545;
}

.screen3-right-content {
  position: absolute;
  top: 45%;
  right: 10%;
  transform: translate(200%, -50%);
}

.screen3-right-pretitle {
  color: #454545;
  font-size: 3vh;
  margin-bottom: 6vh;
}

.screen3-right-pretitle .highlight {
  color: #1629f0;
}

.screen3-right-title svg {
  max-width: 80%;
  width: 50vmin;
  height: 50%;
}

.screen3-button {
  position: absolute;
  bottom: 5%;
  right: 5%;
}

.screen3-heart {
  position: absolute;
  transform: translate(-50%, 50%);
}

.screen3-heart svg {
  max-width: 100%;
  width: 15vmin;
}

.screen4-background {
  height: 100vh;
  background-color: #ffbe4f;
  background: url(./images/screenTasksBg.svg);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}

.screen4-content {
  width: 35%;
  font-size: 2rem;
  color: white;
  position: absolute;
  right: 12%;
  top: 35%;
  text-align: left;
}

.screen4-title {
  font-size: 14vh;
  color: #ffbe4f;
  line-height: 14vh;
}

.screen4-nextbutton {
  position: absolute;
  bottom: 5%;
  right: 5%;
}

.screen5-background {
  color: white;
  background-color: #ffbe4f;
  background: url(./images/screenVideoBg.svg);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  height: 100vh;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  display: grid;
  place-items: center;
}

.screen5-content {
  width: 100%;
  display: grid;
  place-items: center;
}

.screen5-title {
  font-size: 3.5vh;
  background-color: #00b581;
  border-radius: 2vh;
  padding: 2vh;
  width: 70%;
  margin-bottom: 10vh;
}

.screen5-title-inner {
  padding: 2vh;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 1.5vh;
}

.videoscreen-blur {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(20px);
  width: 100%;
  height: 100%;
}

.hide {
  display: none;
  visibility: hidden;
}

.videoscreen-button {
  background-color: #1629f0;
  padding: 20px;
  position: relative;
  z-index: 5;
  margin: -24vh auto 0 auto;
  width: 200px;
  border-radius: 40px;
  font-size: 3vh;
  cursor: pointer;
}

a .videoscreen-button {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.videoscreen-image {
  width: 40%;
  height: 40vh;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 10vh;
}

.video-link {
  z-index: 8;
  width: 100%;
  height: 100%;
}

.video-playbutton {
  z-index: 9;
  background-color: #d60a52;
  padding: 20px;
  position: inherit;
  width: 10%;
  margin: 0 auto 0 auto;
  border-radius: 20px;
  font-size: 2vh;
  transform: translate(0, 30vh);
}

.video-link-wrapper {
  text-decoration: none;
  color: white;
}

.screen5-button-next {
  position: absolute;
  bottom: 5%;
  right: 5%;
}

.endscreen-background {
  height: 100vh;
  background-color: #facfd0;
  background: url(./images/screenEndBg.svg);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}

.endscreen-title {
  color: white;
  font-size: 8vh;
  padding-left: 20vh;
  padding-right: 20vh;
}

.endscreen-subtitle {
  font-size: 3vh;
  font-weight: bold;
  color: #575757;
  padding-left: 35vh;
  padding-right: 35vh;
  margin-top: 5vh;
}

.endscreen-emento {
  position: absolute;
  left: 50%;
  bottom: 10%;
  transform: translate(-50%, 0%);
  width: 20vw;
}

.end-button {
  position: absolute;
  bottom: 5%;
  right: 5%;
}

.Headd {
  opacity: 0;
  pointer-events: none;
  width: 100%;
  height: 100vh;
}

ul.transition {
  display: flex;
  position: relative;
  z-index: 10;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0;
  pointer-events: none;
}

.load-screen2-column {
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  background: #ffb344;
  width: 20%;
}

.screen2-image {
  width: 400px;
}

img {
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
}
